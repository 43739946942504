.schedule-default-section {
    font-size: 13px;
    background-color: white;
    max-width: 100%;
    padding: 1rem 1.5rem;
    box-shadow: 1px 2px 5px #cccccc;
    border-radius: 0.3rem;
}

.schedule-grid-section {
    font-size: 13px;
    background-color: white;
    max-width: 100%;
    padding: 1rem 1.5rem;
    box-shadow: 1px 2px 5px #cccccc;
    border-radius: 0.3rem;
}

.section-title {
    font-size: 14px !important;
}

.newdraw-datefield{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 25px;
}

.red-button {
    color: white !important;
    /* border: 1px solid red !important; */
    font-size: 12px !important;
    background-color: #dc3545 !important;
    box-shadow: none !important;
    /* font-weight: bold !important; */
    border-radius: 0.3rem;
    white-space: nowrap;
    padding: 2px 8px !important;
    text-transform: none !important;
    min-width: 7rem !important;
    min-height: 0.8rem;
}

.green-button {
    color: white !important;
    /* border: 1px solid red !important; */
    font-size: 12px !important;
    background-color: green !important;
    box-shadow: none !important;
    /* font-weight: bold !important; */
    border-radius: 0.3rem;
    white-space: nowrap;
    padding: 2px 8px !important;
    text-transform: none !important;
    min-width: 7rem !important;
}

.blue-button {
    color: white !important;
    /* border: 1px solid red !important; */
    font-size: 12px !important;
    background-color: #2a75ea !important;
    box-shadow: none !important;
    /* font-weight: bold !important; */
    border-radius: 0.3rem;
    white-space: nowrap;
    padding: 2px 8px !important;
    text-transform: none !important;
    min-width: 7rem !important;
}

.section-title {
    padding-bottom: 10px;
    font-weight: bold !important;
}

.cheat-box {
    color: transparent;
    display: flex;
    padding-top: 5px;
}

.sixd-datefield {
    display: flex;
    flex-wrap: wrap;
}

.sixd-component {
    padding-top: 15px;
    width: min-content !important;
    padding-right: 20px;
}

.calendar-label {
    color: #474747 !important;
    padding-bottom: 5px;
}

.searchTitle {
    font-weight: 600 !important;
}

.table-head {
    background-color: #2A75EA1C;
}

@media (max-width: 407px) {
    .schedule-grid-section {
        margin-top: 25px;
    }
}

@media (max-width: 407px) {
    .cheat-box {
    display: none !important;
}
}