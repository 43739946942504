.newUserGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 16px;
}

.inputLabel{
    padding-bottom: 10px;
    font-weight: bold !important;
}

.textField{
    width: 40% ;
}

@media (max-width: 425px) {
    .textField{
        width: 80% ;
    }
}

@media (max-width: 375px) {
    .textField{
        width: 100% ;
    }
}
