.default-section {
    font-size: 13px;
    background-color: white;
    max-width: 100%;
    padding: 1rem 1.5rem;
    box-shadow: 1px 2px 5px #cccccc;
    border-radius: 0.3rem;
    margin-bottom: 20px;
}

.section-title {
    padding-bottom: 10px;
    font-weight: bold !important;
}

.component {
    padding-top: 15px;
}

.savebuttoncomponent {
    padding-top: 20px;
}

.react-datepicker__input-container {
    padding-top: 5px;
}

.calendar-label {
    color: #474747 !important;
    padding-bottom: 5px;
}

.inbetween-field {
    display: flex;
    /* flex-wrap: wrap; */
    padding-top: 10px;
    gap: 20px;
}

.MuiButtonBase {
    min-width: none !important;
}

.css-1l4ne8m-MuiButtonBase-root-MuiButton-root {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.transparent {
    color: transparent;
    display: flex;
    padding-top: 10px;
}

.sixd-datefield {
    display: flex;
    flex-wrap: wrap;
    /* padding-top: 10px; */
}

.sixd-component {
    padding-top: 15px;
    width: min-content !important;
    padding-right: 20px;
}

.pool-range-field {
    display: flex;
    flex-direction: column;
    width: 20%;
    gap: 10px;
}

.sixd-resultfield {
    display: flex;
    flex-direction: column;
    width: 20%;
    gap: 10px;
}

@media (max-width: 407px) {
    .transparent {
    display: none;
}
}

@media (max-width: 425px) {
    .inbetween-field {
        flex-direction: column;
        gap: 15px;
        width: 100% !important;
    }

    .pool-range-field {
        display: flex;
        flex-direction: column;
        width: 100% !important;
    }

    .sixd-resultfield {
        display: flex;
        flex-direction: column;
        width: 100% !important;
    }
    
}

/* need this because the 2 field is too long, and cannot show in flex-direction=row */
@media (max-width: 493px) {
    .inbetween-field {
        display: flex;
        flex-direction: column;
    }

    .pool-range-field {
        width: 70% !important;
    }

    .sixd-resultfield {
        width: 70% !important;
    }

}

@media (max-width: 768px) {
    .inbetween-field {
        
        gap: 15px;
        /* width: 80% ; */
    }

    .pool-range-field {
        display: flex;
        width: 80% ;
    }

    .sixd-resultfield {
        display: flex;
        width: 0%;
    }
}


@media (max-width:1024px) {
    .pool-range-field {
        width: 40%;
    }

    .sixd-resultfield {
        width: 40%;
    }
}



