/* SECTION */
.default-section{
    background-color: white;
    max-width: 100%;
    padding: 30px;
    box-shadow: 1px 2px 5px #cccccc;
    border-radius: 0.3rem;
    margin-bottom: 20px;
}

.default-section-two{
    max-width: 100%;
}

.language-section{
    background-color: #9A9B9D17 ;
    border-radius: 0.5rem;
    width: 50px; 
    height: 37px;
    padding: 2px 10px;
}

/* BUTTONS */
.save-button {
    background-color: #2A75EA !important;
    color: white !important;
    width: 8rem !important;
    font-weight: bold;
    border-radius: 0.5rem;
    padding: 4px 6px !important;
}

.save-button:hover {
    background-color: #2A75EA;
}

.go-button {
    background-color: #2A75EA !important;
    color: white !important;
    width: 1rem !important;
    font-weight: bold;
    border-radius: 0.5rem;
    font-size: 13px !important;
}

.go-button:hover {
    background-color: #2A75EA;
}

.search-button {
    color: white;
    font-size: 13px;
    background-color: #2A75EA !important;
    box-shadow: none;
    font-weight: bold !important;
    border-radius: 0.5rem;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
    text-transform: none !important;
    min-width: 8rem !important;
}

.filter-button {
    color: white;
    font-size: 13px ;
    background-color: #2A75EA !important;
    box-shadow: none;
    font-weight: bold !important;
    border-radius: 0.5rem;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
    text-transform: none !important;
    min-width: 8rem !important;
}

.deactivate-button {
    color: white !important;
    /* border: 1px solid red !important; */
    font-size: 12px !important; 
    background-color: #dc3545 !important;
    box-shadow: none !important;
    font-weight: bold !important;
    border-radius: 0.3rem;
    white-space: nowrap;
    padding: 2px 8px !important;
    text-transform: none !important;
}

.activate-button {
    color: white !important;
    /* border: 1px solid red !important; */
    font-size: 12px !important; 
    background-color: green !important;
    box-shadow: none !important;
    font-weight: bold !important;
    border-radius: 0.3rem;
    white-space: nowrap;
    padding: 2px 8px !important;
    text-transform: none !important;
}

.add-button {
    color: white;
    font-size: 13px;
    background-color: #249624 !important;
    box-shadow: none;
    font-weight: bold !important;
    border-radius: 0.5rem;
    white-space: nowrap;
    padding-left: 30px !important;
    padding-right: 30px !important;
    text-transform: none !important;
}

.calendar-label{
    font-size: 13px;
    color: #474747;
    display: flex;
}

.transparent{
    font-size: 13px;
    color: transparent;
    display: flex;
}

.logout-confirmation{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background-color: white;
    /* border: 2px solid #000; */
    box-shadow: 24px;
    border-radius: 0.5rem;
}

.logout-title{
    padding: 20px !important;
}

.logout-action{
    padding: 20px;
    text-align: center;
}

.confirm-button{
    background-color: #2A75EA !important;
    margin-right: 10px !important;
}

.cancel-button{
    background-color: rgba(157, 155, 155, 0.8) !important;
    margin-left: 10px !important;
}

