.delete-confirmation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background-color: white;
    /* border: 2px solid #000; */
    box-shadow: 24px;
    border-radius: 0.5rem;
}

.delete-action {
    padding: 20px;
    text-align: center;
}

.confirm-button{
    background-color: #2A75EA !important;
    margin-right: 10px !important;
}

.cancel-button{
    background-color: rgba(157, 155, 155, 0.8) !important;
    margin-left: 10px !important;
}