.gridFilterNumber{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    align-items: center;
    padding-bottom: 10px;
}

.labelFilter{
    font-size: 15px;
    padding-top: 5px !important;
    /* padding-right: 8px !important; */
    /* padding-left: 8px !important; */
}

.fieldFilter{
    width: 100%;
}

.gridFilter{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 16px;
    padding: 16px;
}

.details-component {
    display: flex;
    gap: 15px;
    width: 100%;
}

.input-field {
    display: flex;
    gap: 10px;
    align-items: center;
}

.field-label {
    color: "#747474" !important;
    font-size: 13px !important;
    font-weight: bold !important;
}


@media (max-width: 375px) {

    .details-component {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
    }

    .input-field {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: start;
    }

}

@media (max-width: 767px) {
    .details-component {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
    }

    .input-field {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: start;
    }
} 