.search-details {
    padding: 20px 30px 0 30px;
    font-size: 13px !important;
}

.search-dialog-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    border-radius: 0.5rem;
}

.ok-box {
    padding: 20px 50px 20px 20px;
    text-align: end;
}

.ok-box-center {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.ok-button {
    background-color: #2A75EA !important;
}

.searchTitle {
    font-size: 13px !important;
    font-weight: 600px !important;
    text-align: center;
}

.searchValue {
    font-size: 13px !important;
    text-align: center;
}

.searchBox-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #edf0f7;
    border-radius: 0.5rem;
}

.searchValueContainer {
    max-height: 170px;
    overflow-y: auto;
}

.borderedRow {
    border: 1px solid #ccc;
}

.tableCellWithBorder {
    border-right: 1px solid #ccc;
    min-width: 100px;
    /* background-color: #2A75EA1C; */
}

.log-dialog-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    border-radius: 0.5rem;
}

.strike-table{
    padding-bottom: 20px;
}

@media (max-width: 500px) {
    .log-dialog-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        box-shadow: 24px;
        border-radius: 0.5rem;
        max-width: 355px;
    }
}