.form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.save-button {
    background-color: #2A75EA !important;
    color: white !important;
    width: 9rem;
    font-weight: bold;
    border-radius: 0.5rem;
}

.save-button:hover {
    background-color: #2A75EA;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.label {
    color: "#636161";
    font-weight: bold !important;
}

.error {
    font-size: 12px;
    color: red;
}

@media (max-width: 375px) {
    .form {
        gap: 20px !important;
    }
}
