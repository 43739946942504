.details-component {
    display: flex;
    gap: 15px;
    width: 100%;
}

.input-field {
    display: flex;
    gap: 10px;
    align-items: center;
}

.field-label {
    color: "#474747";
    font-size: 13px !important;
    font-weight: bold !important;
}

.total-sum {
    padding: 20px;
    background-color: #f0f4ff;
    border-radius: 1rem ;
}

.sum-text {
    font-size: 12px !important;
    color: #747474;
    text-align: center;
}

.sum-data {
    font-size: 17px !important;
    font-weight: 600 !important;
    color: #747474;
    text-align: center;
}

.sum-box{
    padding: 10px;
}