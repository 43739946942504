.gridButton {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    align-items: center;
    padding-bottom: 10px;
}

.gridFilter {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 5px !important;
    align-items: center;
    padding: 0px !important;
    margin-bottom: 28px;
}

.mainDataSection {
    display: flex;
    padding: 10px 45px;
    transition: all 0.2s ease-in-out;
}

.mainDataSection:hover {
    transform: scale(1.05);
}

.mainTextSection {
    padding: 5px;
}

.mainDataIcon {
    text-align: center;
    height: 35px;
    width: 35px;
    /* transition: all 0.2s ease-in-out;  */
}

/* .mainDataIcon:hover{
    transform: scale(1.2); 
} */

.mainDataResult {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #747474;
}

.mainDataTitle {
    font-size: 13px !important;
    color: #747474;
}

/* .collapsibleCardSection{
    width: 100% !important; 
} */

.collapsibleCard {
    box-shadow: 1px 2px 5px #cccccc !important;
}

.collapsibleCardTitle {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #747474;
    padding-left: 20px;
}

.collapsibleCardInfo {
    padding: 20px;
    display: flex
}

.collapsibleCardIcon {
    text-align: center;
    height: 30px;
    width: 30px;
}

.collapsibleCardInfo {
    font-size: 15px !important;
    color: #747474;
    width: 60%;
    padding: 5px 0px 0px 20px;
    /* padding: 5px 100px 0px 30px; */
}

.collapsibleCardTop10 {
    font-size: 15px !important;
    color: #747474;
    /* width: 28%; */
    width: 40%;
    padding: 5px 0px 0px 30px;
}

.collapsibleCardResult {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #747474;
    width: 50%;
    padding: 5px 0px 0px 30px;
    border-left: 1px soild lightgrey;
}

.collapsibleCardInfoSection {
    border-bottom: 1px solid lightgrey;
    padding-top: 5px;
}

.divider {
    height: 27px !important;
}

.divider_shorter {
    height: 22px !important;
}

.divider_shorterinBox {
    height: 22px !important;
}

.top10BoxInBox {
    display: flex;
    width: 100% !important;
}

/* .refresh-box {
    display: grid;
    padding-top: 20px;
    padding-bottom: 20px;
} */

.header {
    display: flex;
    flex-direction: column !important;
    padding: 15px 0 15px 20px;
    transform: translateX(0);
    transition: all 0.2s ease-in-out;
}

.header:hover{
    transform: scale(1.1) translateX(10px);
}

.date-text {
    font-size: 16px !important;
    color: #747474;
    font-weight: 600 !important;
}

.sync-box {
    padding-left: 20px;
}

.sync-box:hover {
    transform: scale(1.1);
}

.sync-img {
    height: 25px;
    width: 25px;
}

.date-img {
    height: 35px;
    width: 35px;
}

.date-box {
    padding-left: 0px;
    padding-bottom: 10px;
}

.date-label {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #747474;
}

@media (max-width: 425px) {
    .mainDataSection {
        padding: 10px 30px;
    }

    .gridFilter {
        justify-content: center;
    }

    .gridButton {
        justify-content: center;
    }

    .collapsibleCardTop10 {
        padding: 5px 0px 0px 15px;
    }

    .collapsibleCardResult {
        padding: 5px 0px 0px 15px;
    }

    .top10BoxInBox {
        flex-direction: column;
        padding-left: 15px;
    }

    .divider_shorter {
        height: 50px !important;
        margin-left: 15px !important;
    }

    .divider_shorterinBox {
        display: none;
    }
}