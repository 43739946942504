.details-component {
    display: flex;
    gap: 15px;
    width: 100%;
}

.date-component {
    display: flex;
    gap: 15px;
    width: 100%;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.input-field {
    display: flex;
    gap: 10px;
    align-items: center;
}

.field-label {
    color: "#474747";
    font-size: 13px !important;
    font-weight: bold !important;
    /* width: auto !important; */
}

.react-datepicker__input-container {
    padding-top: 5px;
}

@media (max-width: 375px) {

    .details-component {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
    }

    .date-component {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
    }

    .input-field {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: start;
    }

    /* text input field width */
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        width: 100%;
    }

    .react-datepicker__input-container {
        padding-top: 5px;
        width: 100% !important;
    }

    .grid-button {
        display: flex;
        flex-direction: column !important;
        gap: 10px !important;
    }

    .form {
        gap: 20px !important;
    }
}

@media (max-width: 767px) {
    .details-component {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
    }

    .date-component {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
    }

    .input-field {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: start;
    }
}

@media (max-width: 793px) {
    .date-component {
        flex-direction: column;
        align-items: start;
        gap: 15px;
        width: 100%;
    }
}